import { JwtPayload, PerformanceManagementUserProfiles, CommonConstants } from "atfcore-commonclasses";
import { Observable, ObservableInput } from "rxjs";
import { switchMap, take, filter } from "rxjs/operators";
import { authControl, isGenertelTenant } from "../shared/models/global-application-data.model";

export function switchOnce<T, R>(project: (value: T, index?: number) => ObservableInput<R>) {
  return function switchOnceImplementation(source: Observable<T>): Observable<R> {
    return source.pipe(take(1), switchMap(project))
  }
}
export function onceIfNotEmpty<T>(project?: (value: T, index?: number) => ObservableInput<T>) {
  return function switchOnceImplementation(source: Observable<T>): Observable<T> {
    return source.pipe(filter(val => !!val), take(1));
  }
}

export function doApplicationNameRedirect(applicationName: any, decodedJwt: JwtPayload, urlService, router, redirectUrl, queryParamsForAnalyticsCampaign?: string) {
  const applicationContext = urlService.getApplicationUrl().baseUrl;
  const profile = decodedJwt.params && decodedJwt.params.profile;
  const authObject = authControl(decodedJwt.auths);
  let suffixUrl;
  let sessionStorageRedirectUrl: string = sessionStorage.getItem('redirectUrl');
  if (Array.isArray(applicationName)) {
    if (applicationName.length >= 2) {
      router.navigate(['switchLogin']);
    } else {
      switch (applicationName[0]) {
        case "PERFORMANCEMANAGEMENT":
          goToGeneraliProject(decodedJwt, redirectUrl, sessionStorageRedirectUrl, queryParamsForAnalyticsCampaign, applicationContext);
          break;
        case "PERFORMANCEMANAGEMENTGENERTEL":
          goToGenertelProject(profile, sessionStorageRedirectUrl, queryParamsForAnalyticsCampaign, applicationContext, authObject);
          break;
        case "PERFORMANCEMANAGEMENTALLEANZA":
          goToGeneraliProject(decodedJwt, redirectUrl, sessionStorageRedirectUrl, queryParamsForAnalyticsCampaign, applicationContext, true);
          break;
        case "PERFORMANCEMANAGEMENTCLAIMS":
          goToGeneraliProject(decodedJwt, redirectUrl, sessionStorageRedirectUrl, queryParamsForAnalyticsCampaign, applicationContext, false, true);
          break;
        case "PERFORMANCEMANAGEMENTDAS":
          goToGeneraliProject(decodedJwt, redirectUrl, sessionStorageRedirectUrl, queryParamsForAnalyticsCampaign, applicationContext, false, false, true);
          break;
      }
    }
  } else {
    switch (applicationName) {
      case "PERFORMANCEMANAGEMENT":
        goToGeneraliProject(decodedJwt, redirectUrl, sessionStorageRedirectUrl, queryParamsForAnalyticsCampaign, applicationContext);
        break;
      case "PERFORMANCEMANAGEMENTGENERTEL":
        goToGenertelProject(profile, sessionStorageRedirectUrl, queryParamsForAnalyticsCampaign, applicationContext, authObject);
        break;
      case "PERFORMANCEMANAGEMENTALLEANZA":
        goToGeneraliProject(decodedJwt, redirectUrl, sessionStorageRedirectUrl, queryParamsForAnalyticsCampaign, applicationContext, true);
        break;
      case "PERFORMANCEMANAGEMENTCLAIMS":
        goToGeneraliProject(decodedJwt, redirectUrl, sessionStorageRedirectUrl, queryParamsForAnalyticsCampaign, applicationContext, false, true);
        break;
      case "PERFORMANCEMANAGEMENTDAS":
        goToGeneraliProject(decodedJwt, redirectUrl, sessionStorageRedirectUrl, queryParamsForAnalyticsCampaign, applicationContext, false, false, true);
        break;
    }
  }
}

// PM di Generali
export function goToGeneraliProject(decodedJwt, redirectUrl, sessionStorageRedirectUrl, queryParamsForAnalyticsCampaign, applicationContext, isAlleanza?: boolean, isClaims?: boolean, isDas?: boolean) {
  let suffixUrl = '';
  let authObject = null;
  authObject = authControl(decodedJwt && decodedJwt.auths);
  if (authObject.isManager) {
    suffixUrl = "/users/home";
  } else {
    suffixUrl = "/contributor/home";
  }
  let suffixToUse = redirectUrl || sessionStorageRedirectUrl || suffixUrl || '/';

  if (queryParamsForAnalyticsCampaign) {
    // Aggiungo i parametri eventuali per la campagna google analytics, qundi ?utm_source ecc
    suffixToUse = suffixToUse + queryParamsForAnalyticsCampaign;
  }

  if (isAlleanza) {
    window.location.href = applicationContext + "alleanza/#" + suffixToUse;
  } else if (isClaims) {
    window.location.href = applicationContext + "claims/#" + suffixToUse;
  } else if (isDas) {
    window.location.href = applicationContext + "das/#" + suffixToUse;
  } else {
    window.location.href = applicationContext + "gi/#" + suffixToUse;
  }
}

// PM di Genertel
export function goToGenertelProject(profile, sessionStorageRedirectUrl, queryParamsForAnalyticsCampaign, applicationContext, authObject) {
  let suffixUrl = '';
  // if (profile != PerformanceManagementUserProfiles.CONSULTANT && profile != PerformanceManagementUserProfiles.EMPLOYEE && authObject.isManager) {
  if (profile != PerformanceManagementUserProfiles.CONSULTANT && authObject.isManager) {
    suffixUrl = "/users/home";
  } else {
    suffixUrl = "/contributor/home";
  }

  let suffixToUse = sessionStorageRedirectUrl || suffixUrl || '/';
  if (queryParamsForAnalyticsCampaign) {
    // Aggiungo i parametri eventuali per la campagna google analytics, qundi ?utm_source ecc
    suffixToUse = suffixToUse + queryParamsForAnalyticsCampaign;
  }
  window.location.href = applicationContext + "gt/#" + suffixToUse;
}